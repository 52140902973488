<template>
    <div>
        <div class="top_div">
            <div class="search_div_father">
                <common-header title="宁波港物流查询(数据同步EDI)">
                    <div class="input_div_father" slot="content">
                        <span class="inputTitle span">提单号：</span>
                        <div class="input_div">
                            <el-popover
                                v-if="queryInfoList.length"
                                trigger="click"
                                placement="bottom-start"
                                popper-class="blno_history"
                                ref="popoverRef"
                            >
                                <div class="el-autocomplete-suggestion">
                                    <div class="el-scrollbar">
                                        <div
                                            class="el-autocomplete-suggestion__wrap el-scrollbar__wrap"
                                        >
                                            <ul
                                                class="el-scrollbar__view el-autocomplete-suggestion__list"
                                            >
                                                <li
                                                    v-for="item in queryInfoList"
                                                    :key="item.id"
                                                    style="
                                                        display: flex;
                                                        justify-content: space-between;
                                                    "
                                                    @click="
                                                        handleSelectKey(
                                                            item.value,
                                                        )
                                                    "
                                                >
                                                    <span>
                                                        {{ item.value }}
                                                    </span>
                                                    <i
                                                        class="el-icon-delete"
                                                        style="
                                                            line-height: 34px;
                                                            margin-left: 10px;
                                                        "
                                                        @click.stop="
                                                            handleDeleteKey(
                                                                item.id,
                                                            )
                                                        "
                                                    ></i>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>

                                <el-input
                                    slot="reference"
                                    placeholder="请输入提单号"
                                    v-model="searchBlno"
                                    clearable
                                    maxlength="30"
                                    @input="checkInputBlno()"
                                    class="input-with-select-top"
                                    @keyup.enter.native="getBoxInfo()"
                                    style="width: 300px"
                                ></el-input>
                            </el-popover>
                            <el-input
                                v-else
                                slot="reference"
                                placeholder="请输入提单号"
                                maxlength="30"
                                v-model="searchBlno"
                                clearable
                                @input="checkInputBlno()"
                                class="input-with-select-top"
                                style="width: 300px"
                                @keyup.enter.native="getBoxInfo()"
                            ></el-input>
                        </div>
                        <span class="inputTitle span">集装箱号：</span>
                        <div class="input_div">
                            <el-input
                                placeholder="请输入集装箱号"
                                v-model="containerNum"
                                clearable
                                @input="checkContainerNum()"
                                class="input-with-select-top"
                                style="width: 250px"
                                @keyup.enter.native="getBoxInfo()"
                            ></el-input>
                        </div>
                        <div class="btn_div">
                            <el-button
                                type="primary"
                                class="refresh_btn"
                                @click="getBoxInfo()"
                                :loading="totalLoading || buttonLoading"
                            >
                                查询进港信息
                            </el-button>
                            <el-button
                                class="refresh_btntwo"
                                :loading="totalLoading || buttonLoading"
                                @click="resetAll()"
                            >
                                重置
                            </el-button>
                        </div>
                    </div>
                </common-header>
            </div>
        </div>

        <div class="data_div">
            <div class="data_search_div">
                <div class="data_search_left_div">
                    <h1 class="status">| 箱货跟踪</h1>
                    <span
                        class="span-status"
                        :style="{
                            'background-color':
                                this.boxStatus[this.boxStatus.length - 1] ==
                                    '码头不能放行' ||
                                this.boxStatus[this.boxStatus.length - 1] ==
                                    '码头取消放行'
                                    ? '#FF98A9'
                                    : 'rgba(125, 220, 255, 0.2)',
                        }"
                    >
                        {{ this.boxStatus[this.boxStatus.length - 1] }}
                    </span>
                </div>

                <div class="flex_div">
                    <column-set
                        :table-list="queryList"
                        page-key="boxQuery_inGate"
                        :reset-data="{ NB: QUERYTABLE.queryTable }"
                        save-mutation="table/queryTableList"
                        reset-mutation="table/queryTableList"
                    ></column-set>
                </div>
            </div>

            <div class="table_div">
                <el-table
                    border
                    stripe
                    v-loading="loadingIngate"
                    :element-loading-text="loadingIngateText"
                    max-height="190"
                    ref="multipleTable"
                    :key="tableKey"
                    :cell-class-name="cellClass"
                    :data="blonBoxInfo"
                    style="width: 100%"
                    :default-sort="{ prop: 'date', order: 'descending' }"
                    :header-cell-style="{
                        background: '#eef1f6',
                        color: '#606266',
                    }"
                >
                    <el-table-column
                        type="index"
                        label="序号"
                        width="80"
                    ></el-table-column>

                    <template v-for="item in queryList">
                        <el-table-column
                            v-if="item.show && item.prop.includes('Time')"
                            :prop="item.prop"
                            :sortable="item.sortable"
                            :label="item.label"
                            :width="item.width"
                            :resizable="item.resizable"
                            :fixed="item.fixed"
                        >
                            <template slot-scope="scope">
                                {{ formatTime(scope.row[item.prop]) }}
                            </template>
                        </el-table-column>
                        <el-table-column
                            v-else-if="item.show && item.prop != 'blno'"
                            show-overflow-tooltip
                            :prop="item.prop"
                            :sortable="item.sortable"
                            :label="item.label"
                            :width="item.width"
                            :resizable="item.resizable"
                            :fixed="item.fixed"
                        ></el-table-column>

                        <el-table-column
                            v-else-if="item.show && item.prop == 'blno'"
                            :prop="item.prop"
                            :sortable="item.sortable"
                            :label="item.label"
                            :width="item.width"
                            :resizable="item.resizable"
                            :fixed="item.fixed"
                        >
                            <template slot-scope="scope">
                                <span
                                    @click="
                                        (showModal = true),
                                            (idxNum = scope.row.num - 1)
                                    "
                                    style="color: blue; cursor: pointer"
                                >
                                    {{ scope.row.blno }}
                                </span>
                                <ShipDialog title="拼票详细信息" :visible.sync="showModal" showTab="NB">
                                    <div style="margin-top: 20px">
                                        <el-table
                                            :data="allBoxInfo[idxNum]"
                                            :append-to-body="true"
                                            style="width: 100%"
                                        >
                                            <el-table-column
                                                prop="num"
                                                label="NO"
                                            ></el-table-column>
                                            <el-table-column
                                                prop="blno"
                                                label="提单号"
                                            ></el-table-column>
                                            <el-table-column
                                                prop="packageNum"
                                                label="件数"
                                            ></el-table-column>
                                            <el-table-column
                                                prop="weight"
                                                label="毛重(kg)"
                                            ></el-table-column>
                                            <el-table-column
                                                prop="measure"
                                                label="尺寸(m3)"
                                            ></el-table-column>
                                        </el-table>
                                    </div>
                                    <DialogFooter confirmText="" @cancel="showModal = false" cancelText="确定" showTab="NB"></DialogFooter>
                                </ShipDialog>
                            
                            </template>
                        </el-table-column>
                    </template>
                </el-table>
                <div class="search_sailing">
                    <div class="input_div_father">
                        <span class="spantwo inputTitle">船名：</span>
                        <div class="input_div">
                            <el-autocomplete
                                popper-class="vessel-voyage-popper"
                                placeholder="请输入英文船名"
                                v-model="vesselename"
                                @input="checkInputVesselename()"
                                @change="checkInputVesselename2(vesselename)"
                                :fetch-suggestions="shipSearch"
                                clearable
                                @select="handleShipSelect"
                                class="input-with-select-sailing"
                            >
                                <template slot-scope="{ item }">
                                    <div
                                        style="
                                            font-size: 12px;
                                            display: block;
                                            overflow: hidden;
                                            word-break: keep-all;
                                            white-space: nowrap;
                                            text-overflow: ellipsis;
                                        "
                                    >
                                        {{ item.value }}
                                    </div>
                                </template>
                            </el-autocomplete>
                        </div>
                        <span class="spantwo inputTitle">航次：</span>
                        <div class="input_div">
                            <el-input
                                placeholder="请输入航次"
                                v-model="voyage"
                                clearable
                                @input="checkInputVoyage()"
                                class="input-with-select-sailing"
                            ></el-input>
                        </div>
                        <div class="btn_div">
                            <el-button
                                class="refresh_btn"
                                @click="getShipInfo()"
                                :loading="loadingShipInfo || buttonLoading"
                            >
                                查询船期信息
                            </el-button>
                            <el-button
                                :loading="loadingShipInfo || buttonLoading"
                                class="refresh_btntwo"
                                @click="reset()"
                            >
                                重置
                            </el-button>
                        </div>
                    </div>
                </div>

                <div class="data_search_div" key="shipInfo">
                    <div class="data_search_left_div">
                        <h1 class="status">| 船期</h1>
                        <span class="span-status">
                            {{ this.shipStatus[this.shipStatus.length - 1] }}
                        </span>
                    </div>

                    <div class="flex_div">
                        <column-set
                            :table-list="queryList2"
                            page-key="boxQuery_shipInfo"
                            :reset-data="{ NB: QUERYTABLE2.queryTable2 }"
                            save-mutation="table/queryTableList2"
                            reset-mutation="table/queryTableList2"
                        ></column-set>
                    </div>
                </div>
                <el-table
                    border
                    stripe
                    v-loading="loadingShipInfo"
                    max-height="190"
                    ref="multipleTable"
                    :key="tableKey2"
                    :cell-class-name="cellClass"
                    :data="shipInfo"
                    style="width: 100%"
                    :default-sort="{ prop: 'date', order: 'descending' }"
                    :header-cell-style="{
                        background: '#eef1f6',
                        color: '#606266',
                    }"
                >
                    <el-table-column
                        prop="num"
                        label="序号"
                        width="80"
                    ></el-table-column>

                    <template v-for="item in queryList2">
                        <el-table-column
                            v-if="item.show"
                            show-overflow-tooltip
                            :prop="item.prop"
                            :sortable="item.sortable"
                            :label="item.label"
                            :width="item.width"
                            :key="item.prop"
                            :resizable="item.resizable"
                            :fixed="item.fixed"
                        >
                            <template slot-scope="scope">
                                <span v-if="item.prop.includes('Time')">
                                    {{ formatTime(scope.row[item.prop]) }}
                                </span>
                                <span v-else>
                                    {{ scope.row[item.prop] }}
                                </span>
                            </template>
                        </el-table-column>
                    </template>
                </el-table>

                <div class="search_sailing">
                    <div class="input_div_father">
                        <span class="spantwo inputTitle">提单号：</span>
                        <div class="input_div">
                            <el-input
                                placeholder="请输入提单号"
                                maxlength="30"
                                v-model="searchBlno2"
                                clearable
                                @input="checkInputDock()"
                                @keyup.enter.native="getDockInfo(searchBlno2)"
                                class="input-with-select-top"
                            ></el-input>
                        </div>
                        <el-button
                            class="refresh_btn"
                            :loading="loadingFwPreInfo || buttonLoading"
                            @click="
                                (dockButton = true), getDockInfo(searchBlno2)
                            "
                        >
                            查询
                        </el-button>
                    </div>
                </div>
                <div class="data_search_div" key="fwPreInfo">
                    <div class="data_search_left_div">
                        <h1 class="status">| 预配舱单</h1>
                    </div>
                    <div class="flex_div">
                        <column-set
                            :table-list="queryList3"
                            page-key="boxQuery_fwPreInfo"
                            :reset-data="{ NB: QUERYTABLE3.queryTable3 }"
                            save-mutation="table/queryTableList3"
                            reset-mutation="table/queryTableList3"
                        ></column-set>
                    </div>
                </div>
                <el-table
                    border
                    stripe
                    v-loading="loadingFwPreInfo"
                    max-height="190"
                    ref="multipleTable"
                    :key="tableKey3"
                    :cell-class-name="cellClass"
                    :data="dockInfo"
                    style="width: 100%"
                    :default-sort="{ prop: 'date', order: 'descending' }"
                    :header-cell-style="{
                        background: '#eef1f6',
                        color: '#606266',
                    }"
                >
                    <el-table-column
                        prop="num"
                        label="序号"
                        width="120"
                    ></el-table-column>

                    <template v-for="item in queryList3">
                        <el-table-column
                            v-if="item.show"
                            show-overflow-tooltip
                            :prop="item.prop"
                            :sortable="item.sortable"
                            :label="item.label"
                            :min-width="item.width"
                            :key="item.prop"
                            :resizable="item.resizable"
                            :fixed="item.fixed"
                        >
                            <template slot-scope="scope">
                                <template v-if="item.prop === 'ctnInfoArr'">
                                    <div v-for="ctn in scope.row.ctnInfoArr" :key="ctn.contaId">
                                        {{ `${ctn.contaId} / ${ctn.contaSizeType}` }}
                                    </div>
                                </template>
                                <template v-else>
                                    <span v-if="item.prop.includes('Time')">
                                        {{ formatTime(scope.row[item.prop]) }}
                                    </span>
                                    <span v-else-if="item.prop === 'portCode'">{{ `${scope.row.portCodeText}(${scope.row.portCode})` }}</span>
                                    <span v-else>
                                        {{ scope.row[item.prop] }}
                                    </span>
                                </template>
                            </template>
                        </el-table-column>
                    </template>
                </el-table>
            </div>
        </div>
    </div>
</template>

<script>
import CommonHeader from "@/components/common/CommonHeader";
import ColumnSet from "@/components/common/ColumnSet";
import moment from "moment";
import ShipDialog from "@/components/layout/ShipDialog.vue";
import DialogFooter from "@/components/layout/DialogFooter.vue";

import {
    searchHistorySuggestion,
    deleteHistorySuggestion,
    searchShip,
    queryBoxlist,
    addHistorySuggestion,
    queryShipInfo,
    queryInGates,
    queryShip,
} from "@/api/boxQuery";
import {
    checkCusmovIsCheck,
    getMatou,
    setRightForrmatSpace2,
    setRightForrmat,
    formatterTime,
} from "@/utils/validate";
import { checkCurUrlCanSkipLogin, passFlag2String,getManifestInfo } from "@/utils";
export default {
    name: "BoxQuery",
    data() {
        return {
            icon_green: require("@/assets/img/green.png"),
            idxNum: 0,
            showModal: false,
            popoverVisible: false,
            popoverContent: "",
            loadingShipInfo: false, // 船期信息加载
            loadingFwPreInfo: false, // 预配舱单信息加载
            loadingIngate: false, // 进港信息加载
            loadingIngateText: '', // 进港信息加载文案
            test: "测试信息",
            loginInterval: "",
            vesselename: "",
            voyage: "",
            blno: "",
            statusOfBox: "",
            statusOfShip: "",
            searchBlno: "", //搜索框提单号
            searchBlno2: "", //搜索框提单号2
            searchNppstatus: "", //筛选框码头放行
            searchCustomflag: "", //筛选框海放
            containerNum: "", //集装箱号
            totalDatalist: [], //存储每次请求的数据
            totalvoyagelist: [], //存放船期数据shipInfo
            lcltotalBoxList2: [], //temp存放原始数组用于筛选
            boxList: [], //展示用的列表数据
            multipleSelectionShip: [], //勾选中船期的列表
            multipleSelectionBox: [], //勾选中箱货的列表
            multipleSelectionDock: [], //勾选中预配单的列表
            lcltableList: [],
            // 表格key
            tableKey: "inGateTable",
            tableKey2: "shipInfoTable",
            tableKey3: "fwPreInfoTable",
            isRepet: 0,
            boxInfo: [], //全部信息
            boatInfo: [],
            shipInfo: [], //船期信息
            dockInfo: [], //预配舱单信息
            allBoxInfo: [], //弹窗信息
            blonBoxInfo: [], //箱货跟踪信息
            arrivalTimeState: [], //是否进门
            boxStatus: [], //箱货状态
            shipStatus: [], //船期状态
            liststate: 0,
            // 列设置中 tree配置
            defaultProps: {
                children: "children",
                label: "label",
            },
            pageTotal: 0,
            pageSize: 10,
            pagePosition: 0,
            buttonLoading: false,
            portcloseFlag: "0", //截关截港筛选，0：全部，1：截关，2：截港
            is_portclose: false, //临近截关期
            is_ctnapplyend: false, //临近截港期
            dockButton: false, //判断是否按第三行按钮
            queryInfoList: [], // 查询提单号历史记录数据
        };
    },
    components: {
        CommonHeader,
        ColumnSet,
        ShipDialog,
        DialogFooter,
    },
    mounted() {
        this.Init();
    },
    computed: {
        queryList: {
            get() {
                return this.$store.state.table.queryList;
            },
            set(val) {
                this.$store.commit("table/queryTableList", val);
            },
        },
        queryList2: {
            get() {
                return this.$store.state.table.queryList2;
            },
            set(val) {
                this.$store.commit("table/queryTableList2", val);
            },
        },
        queryList3: {
            get() {
                return this.$store.state.table.queryList3;
            },
            set(val) {
                this.$store.commit("table/queryTableList3", val);
            },
        },
        totalLoading() {
            return (
                this.loadingIngate ||
                this.loadingShipInfo ||
                this.loadingFwPreInfo
            );
        },
    },
    methods: {
        Init() {
            // 获取搜索记录
            this.querySuggestions();
            if (checkCurUrlCanSkipLogin()) {
                this.voyage = this.$route.query.voyage;
                this.vesselename = this.$route.query.vessel;
                this.getShipInfo(this.vesselename, this.voyage);
                return;
            }
            // 如果有数据渲染本地数据，减少请求
            if (this.$store.state.ship.blonBoxInfo) {
                this.blonBoxInfo = this.$store.state.ship.blonBoxInfo;
            }
            if (this.$store.state.ship.shipInfo) {
                this.shipInfo = this.$store.state.ship.shipInfo;
            }
            if (this.$store.state.ship.dockInfo) {
                this.dockInfo = this.$store.state.ship.dockInfo;
            }
            if (this.$store.state.ship.allBoxInfo) {
                this.allBoxInfo = this.$store.state.ship.allBoxInfo;
            }
            if (this.$store.state.ship.boxStatus) {
                this.boxStatus = this.$store.state.ship.boxStatus;
            }
            if (this.$store.state.ship.shipStatus) {
                this.shipStatus = this.$store.state.ship.shipStatus;
            }

            if (
                this.$store.state.table.queryList.length ===
                this.RESETTABLE.queryTable.length
            ) {
                this.queryList = this.$store.state.table.queryList;
            } else {
                this.queryList = this.QUERYTABLE.queryTable;
            }
            if (
                this.$store.state.table.queryList2.length ===
                this.RESETTABLE.queryTable2.length
            ) {
                this.queryList2 = this.$store.state.table.queryList2;
            } else {
                this.queryList2 = this.QUERYTABLE2.queryTable2;
            }
            if (
                this.$store.state.table.queryList3.length ===
                this.RESETTABLE.queryTable3.length
            ) {
                this.queryList3 = this.$store.state.table.queryList3;
            } else {
                this.queryList3 = this.QUERYTABLE3.queryTable3;
            }
            this.$store.commit("table/editListState", this.liststate);
        },

        // 处理提单号历史记录

        // 选择
        handleSelectKey(item) {
            // 赋值
            this.searchBlno = item;
            // 自动失去焦点
            this.$refs.popoverRef.doClose();
            // 查询
            this.getBoxInfo();
        },

        // 查询历史记录
        querySuggestions() {
            searchHistorySuggestion({
                // code: this.$store.state.other.systemKey,
                code: "NB",
            }).then(({ data }) => {
                this.queryInfoList = data.data.map((item) => ({
                    value: item.blno,
                    id: item.id,
                }));
            });
        },

        // 删除
        handleDeleteKey(id) {
            // 为了用户体验
            // 前端先手动删除
            this.queryInfoList = this.queryInfoList.filter(
                (item) => item.id !== id,
            );
            // 后端再同步
            deleteHistorySuggestion(id);
        },

        // 船期添加模糊查询
        checkInputVesselename() {
            if (this.vesselename) {
                this.vesselename = setRightForrmatSpace2(this.vesselename);
            }
            if (this.vesselename.length >= 4) {
                searchShip({ vesselsName: this.vesselename })
                    .then(({ data }) => {
                        if (data.data?.length > 0) {
                            this.totalshipList = data.data.map(({ v, s }) => ({
                                value: `${s}/${v}`,
                            }));
                        } else {
                            this.totalshipList = [];
                        }
                    })
                    .catch(() => {
                        this.totalshipList = [];
                    });
            } else if (this.vesselename.length < 4) {
                this.totalshipList = [];
            }
        },

        //全部船名航次搜索框带输入建议
        shipSearch(queryString, cb) {
            let restaurants = this.totalshipList || [];
            let results = queryString
                ? restaurants.filter(this.shipFilter(queryString))
                : restaurants;
            // 调用 callback 返回建议列表的数据
            cb(results);
        },
        shipFilter(queryString) {
            return (restaurant) => {
                return (
                    restaurant.value.indexOf(queryString.toUpperCase()) != -1
                );
            };
        },

        resetFather(type) {
            this.reset();
            if (type !== "table") {
                this.searchBlno = "";
                this.searchBlno2 = "";
                this.containerNum = "";
            }
            this.boxStatus = [];
            this.blonBoxInfo = [];
            this.allBoxInfo = [];
            this.totalDatalist = [];
            this.dockInfo = [];
            this.tableKey = Math.random();
            this.$store.commit("ship/editBlonBoxInfo", this.blonBoxInfo);
            this.$store.commit("ship/editShipInfo", this.shipInfo);
            this.$store.commit("ship/editDockInfo", this.dockInfo);
            this.$store.commit("ship/editAllBoxInfo", this.allBoxInfo);
            this.$store.commit("ship/editBoxStatus", this.boxStatus);
            this.$store.commit("ship/editShipStatus", this.shipStatus);
        },
        reset() {
            this.shipInfo = [];
            this.vesselename = "";
            this.voyage = "";
            this.shipStatus = [];
        },

        //检测订阅跟踪输入的关键字格式
        checkInputBlno() {
            if (this.searchBlno) {
                this.searchBlno = setRightForrmat(this.searchBlno);
            }
        },
        checkInputDock() {
            if (this.searchBlno2) {
                this.searchBlno2 = setRightForrmat(this.searchBlno2);
            }
        },

        checkInputVoyage() {
            if (this.voyage) {
                this.voyage = setRightForrmat(this.voyage);
            }
        },
        checkContainerNum() {
            if (this.containerNum) {
                this.containerNum = setRightForrmat(this.containerNum);
            }
        },

        getBoxInfo() {
            if (!(this.searchBlno || this.containerNum)) {
                this.$message.warning("箱号、提单号不得全为空");
                return;
            }
            this.resetFather("table");
            this.loadingIngate = true;
            this.loadingShipInfo = true;
            this.loadingFwPreInfo = true;
            let param = {
                blno: this.searchBlno,
                ctnno: this.containerNum,
                // code: this.$store.state.other.systemKey,
                code: "NB",
            };

            // 箱货跟踪
            queryInGates(param)
                .then(async ({ data }) => {
                    if (this.searchBlno && data.data.length !== 0) {
                        // 插入历史记录提单号数据
                        addHistorySuggestion({
                            blno: this.searchBlno,
                            // code: this.$store.state.other.systemKey,
                            code: "NB",
                        }).then(() => {
                            // 查询历史记录提单号数据
                            this.querySuggestions();
                        });
                    }
                    this.boxData(data.data);
                    let vesseleName = "";
                    let voyage = "";
                    if (data.data.length === 0 || !data.data) {
                        // 箱货跟踪无数据，不去查询船期
                        this.loadingShipInfo = false;
                        return;
                    } else {
                        const { npp, ctn = {} } = data.data[0];
                        vesseleName = npp ? npp.vesselsName : ctn.vesselsName;
                        voyage = npp ? npp.voyage : ctn.voyage;
                    }
                    if (!vesseleName || !voyage) {
                        // 船名或航次为空，不去查询船期
                        this.loadingShipInfo = false;
                        return;
                    }

                    const params = {
                        vessels: vesseleName,
                        voyage: voyage,
                    };
                    queryShip(params)
                        .then(({ data }) => {
                            this.shipInfo = [];
                            this.shipStatus = [];
                            data.data
                                ? this.shipData(data.data)
                                : this.$message.warning(
                                      "暂无此船名或航次的船期信息",
                                  );
                        })
                        .finally(() => {
                            this.loadingShipInfo = false;
                        });
                })
                .finally(() => {
                    this.loadingIngate = false;
                    this.loadingShipInfo = false;
                });
            //预配舱单
            getManifestInfo(this.searchBlno).then(data => {
                this.dockInfo = [];
                this.dockData(data)
            }).finally(() => { 
                this.loadingFwPreInfo = false;
            })
        },

        // 根据船名航次查询船期
        getShipInfo(vesselename = this.vesselename, voyage = this.voyage) {
            if (!vesselename) {
                this.$message.warning("船名不得为空");
                return;
            } else if (!voyage) {
                this.$message.warning("航次不得为空");
                return;
            }
            this.buttonLoading = true;
            queryShipInfo(vesselename, voyage)
                .then(({ data }) => {
                    this.shipInfo = [];
                    this.shipStatus = [];
                    data.data
                        ? this.shipData(data.data)
                        : this.$message.warning("暂无此船名或航次的船期信息");
                })
                .finally(() => {
                    this.buttonLoading = false;
                });
        },

        // 查询预配舱单信息
        getDockInfo(blno) {
            if (!blno) {
                this.$message.warning("提单号不得为空");
                return;
            }
            this.buttonLoading = true;
            this.dockInfo = [];
            getManifestInfo(blno).then(data => {
                this.dockData(data)
            }).finally(() => { 
                this.buttonLoading = false;
            })
        },

        // 判断当前箱号是否有对应的进港信息
        findCtnInNpplist(ctnno, nppList) {
            return ~nppList.findIndex(({ ctn, npp }) =>
                [ctn?.ctnno, npp?.ctnno].includes(ctnno),
            );
        },
        // 处理分票数据
        handleFixData(data, type) {
            const box_mess_list = this.getString2JsonArr(data) || [];
            let promiseArr = [];
            box_mess_list.reverse().forEach(box => { 
                promiseArr.push(queryInGates({
                    blno: box.blno,
                    ctnno: '',
                    // code: this.$store.state.other.systemKey,
                    code: "NB",
                }))
            })
            this.blonBoxInfo = [];
            this.loadingIngate = true;
            this.loadingIngateText = '正在查询拼票数据...'
            this.$nextTick(() => { 
                Promise.all(promiseArr).then(res => { 
                    let allBoxArr = [];
                    res.forEach(item => { allBoxArr = allBoxArr.concat(item.data.data) })
                    for (let i = 0; i < allBoxArr.length; i++) {
                        let temp1 = {
                            num: i+1,
                            ...allBoxArr[i],
                        };
                        let { ctn = {}, npp = {} } = temp1;
                        ctn = ctn ?? {};
                        npp = npp ?? {};
                        this.boxsearchBlon(temp1, true);
                        // 获取提单号集装箱数据
                        const box_mess_data = this.getString2JsonArr(ctn.bills || npp.bills).reverse().map((item, index) => {
                            return type === "ctn"
                                ? {
                                    ...item,
                                    measure:
                                        item.measure.substr(0, 1) === "."
                                            ? "0" + item.measure
                                            : item.measure,
                                    num: index + 1,
                                }
                                : {
                                    num: index + 1,
                                    blno: item.billno,
                                };
                        });
                        this.allBoxInfo.push(box_mess_data);
                    }
                }).finally(() => { 
                    this.loadingIngate = false;
                    this.loadingIngateText = '';
                })
            })
            this.$store.commit("ship/editAllBoxInfo", this.allBoxInfo);
        },

        boxData(data) {
            // if (data.nppInGateEmptyVoList.length == 0)
            if (data.length == 0) {
                const errorStr = this.searchBlno ? "提单号" : "集装箱号";
                this.$message.error(`该${errorStr}没有数据`);
                return;
            }
            let tempAllbox = [];
            this.blonBoxInfo = [];
            this.boxInfo = data;
            this.totalDatalist.push(data);
            // this.nppinfoResultVo = this.boxInfo.nppInGateEmptyVoList || [];

            this.allBoxInfo = [];
            tempAllbox.num = this.allBoxInfo.length + 1; // 序号
            let cacheBills
            let billsFrom
            for (let i = 0; i < this.boxInfo.length; i++) {
                let temp1 = {
                    ...this.boxInfo[i],
                };
                let { ctn = {}, npp = {} } = temp1;
                ctn = ctn ?? {};
                npp = npp ?? {};

                if (ctn.bills) { 
                    cacheBills = ctn.bills
                    billsFrom = 'ctn'
                }
                if (npp.bills && !ctn.bills) { 
                    cacheBills = npp.bills
                    billsFrom = 'npp'
                }
                if (!ctn.bills && !npp.bills) { 
                    if (this.searchBlno != "") {
                        // 提单号不为空
                        if (this.containerNum != "") {
                            // 集装箱号不为空
                            if (temp1) {
                                if (temp1.ctn.ctnno == this.containerNum) {
                                    // 找到与当前搜索集装箱号匹配的数据进行处理
                                    this.blonSearchBox(temp1);
                                }
                            }
                        } else {
                            if (temp1) {
                                // 处理当前提单号全部数据
                                this.blonSearchBox(temp1);
                            }
                        }
                    } else {
                        this.boxsearchBlon(temp1);
                    }
                }
            }
            if (cacheBills) { 
                 // 拼票数据
                this.handleFixData(cacheBills, billsFrom);
            }
            if (this.blonBoxInfo) {
                this.$store.commit("ship/editBlonBoxInfo", this.blonBoxInfo);
                this.searchBlno = "";
                this.containerNum = "";
            }
        },

        checkInputVesselename2(val) {
            if (val) {
                var len = val.length - 1;
                if (val[len] == "." || val[len] == "/") {
                    this.vesselename = val.substring(0, len);
                }
            }
        },

        blonSearchBox(data) {
            this.blonBoxdata = { ...data };
            let temp2 = {
                num: this.blonBoxInfo.length + 1,
                blno: this.searchBlno,
                cpcode_flag: "",
                ifcsum_flag: "",
                custom_flag: "",
                sld_flag: "",
                ctnNo: "",
                cpcode: "",
                sizetype: "",
                pass_flag: "",
                remark: "",
                grossWeight: "",
                packageNum: "",
                boxCusmov: "",
                ctnNo: "",
                sealNo: "",
                arrivalTime: formatterTime(""),
                loadTime: formatterTime(""),
                sailingTime: formatterTime(""),
                Vesselnamevoyage: "",
                dischargeport: "",
            };

            const { npp, ctn } = this.blonBoxdata;

            if (npp) {
                const box_flag_list = this.getString2JsonArr(npp.bills);
                temp2 = { ...temp2, ...box_flag_list[0] };
                temp2.ctnNo = npp.ctnno;
                temp2.cpcode = getMatou(npp.wharf);
                temp2.sizetype = npp.size + npp.type;
                temp2.pass_flag = passFlag2String(npp.passFlag);
                temp2.remark = npp.remark;
            }

            if (ctn) {
                this.tableKey = Math.random();
                const box_mess_list = this.getString2JsonArr(ctn.bills) || [];
                box_mess_list.forEach((item) => {
                    // temp2.blno = item.blno
                    if (temp2.blno == item.blno) {
                        temp2.grossWeight = item.weight;
                        // temp2.measure = item.measure
                        temp2.packageNum = item.packageNum;
                    }
                });
                // 如果ctn里面有，取ctn的值(start)
                temp2.cpcode = getMatou(ctn.wharf);
                temp2.sizetype = ctn.size + ctn.type;
                // end
                temp2.boxCusmov = checkCusmovIsCheck(ctn.checkType);
                temp2.ctnNo = ctn.ctnno;
                temp2.sealNo = ctn.sealno;
                temp2.arrivalTime = ctn.inGateTime;
                this.arrivalTimeState = Boolean(temp2.arrivalTime);
                temp2.loadTime = ctn.loadTime || "-"; //装船时间
                temp2.sailingTime = ctn.departureTime || "-"; //离泊时间
                temp2.Vesselnamevoyage = ctn.vesselsName + "/" + ctn.voyage;
                temp2.dischargeport = ctn.dlPortCode; //装/卸货港
            }
            this.blonBoxInfo.push(temp2);
            this.BoxStatus(temp2);
            this.$store.commit("ship/editBoxStatus", this.boxStatus);
        },

        //订阅框船名航次搜索框选择了输入建议
        handleShipSelect(item) {
            var selectShip = item.value.split("/");
            this.vesselename = selectShip[0];
            this.voyage = selectShip[1];
        },

        boxsearchBlon(data,isSub) {
            this.blonBoxdata = data;
            const { ctn, npp } = this.blonBoxdata;
            if (ctn) {
                this.tableKey = Math.random();
                let box_mess_list = this.getString2JsonArr(ctn.bills);
                const emptyTemp = {
                    num: this.blonBoxInfo.length + 1,
                    blno: "",
                    cpcode_flag: "",
                    ifcsum_flag: "",
                    custom_flag: "",
                    sld_flag: "",
                    ctnNo: "",
                    cpcode: "",
                    sizetype: "",
                    pass_flag: "",
                    remark: "",
                    grossWeight: "",
                    packageNum: "",
                    boxCusmov: "",
                    ctnNo: "",
                    sealNo: "",
                    arrivalTime: formatterTime(""),
                    loadTime: formatterTime(""),
                    sailingTime: formatterTime(""),
                    Vesselnamevoyage: "",
                    dischargeport: "",
                };
                if (isSub) { 
                    // 拼票
                    box_mess_list = box_mess_list.filter(box => box.blno === ctn.blno)
                }
                box_mess_list.forEach((item, index) => {
                    let temp2 = { ...emptyTemp };
                    if (npp) {
                        const box_flag_list = this.getString2JsonArr(npp.bills);
                        temp2 = {
                            ...emptyTemp,
                            ...box_flag_list[index],
                        };
                        temp2.cpcode = getMatou(npp.wharf);
                        temp2.sizetype = npp.size + npp.type;
                        temp2.pass_flag = passFlag2String(npp.passFlag);
                        temp2.remark = npp.remark;
                    }
                    // 如果ctn里面有，取ctn的值(start)
                    temp2.cpcode = getMatou(ctn.wharf);
                    temp2.sizetype = ctn.size + ctn.type;
                    // end
                    temp2.blno = item.blno;
                    temp2.grossWeight = item.weight;
                    temp2.packageNum = item.packageNum;
                    temp2.boxCusmov = checkCusmovIsCheck(ctn.checkType);
                    temp2.ctnNo = ctn.ctnno;
                    temp2.sealNo = ctn.sealno;
                    temp2.arrivalTime = ctn.inGateTime;
                    this.arrivalTimeState = Boolean(temp2.arrivalTime);
                    temp2.loadTime = ctn.loadTime; //装船时间
                    temp2.sailingTime = ctn.departureTime; //离泊时间
                    temp2.Vesselnamevoyage = ctn.vesselsName + "/" + ctn.voyage;
                    temp2.dischargeport = ctn.dlPortCode; //装/卸货港
                    this.blonBoxInfo.push(temp2);
                    this.BoxStatus(temp2);
                    this.$store.commit("ship/editBoxStatus", this.boxStatus);
                });
            }

            if (npp && !ctn) {
                let box_mess_list = this.getString2JsonArr(npp.bills);
                if (isSub) { 
                    // 拼票
                    box_mess_list = box_mess_list.filter(box => box.blno === npp.blno)
                }
                box_mess_list.forEach((item) => {
                    let temp2 = { ...emptyTemp };
                    temp2.num = this.blonBoxInfo.length + 1;
                    temp2.blno = item.billno;
                    temp2.cpcode_flag = item.cpcode_flag;
                    temp2.ifcsum_flag = item.ifcsum_flag;
                    temp2.custom_flag = item.custom_flag;
                    temp2.sld_flag = item.sld_flag;
                    temp2.ctnNo = npp.ctnno;
                    temp2.cpcode = getMatou(npp.wharf);
                    temp2.sizetype = npp.sizeType;
                    temp2.pass_flag = passFlag2String(npp.passFlag);
                    temp2.remark = npp.remark;

                    this.blonBoxInfo.push(temp2);
                    this.BoxStatus(temp2);
                    this.$store.commit("ship/editBoxStatus", this.boxStatus);
                });
                this.tableKey = Math.random();
            }
            console.log(this.blonBoxInfo,'------blnoBoxInfo')
        },

        BoxStatus(data) {
            this.boxStatus = [];
            var nowD = new Date(); //当前时间

            if (data.sailingTime && data.sailingTime != "-") {
                var endD = new Date(data.sailingTime);
                if (nowD.getTime() > endD.getTime()) {
                    this.boxStatus.push("已离泊");
                }
            } else if (data.loadTime && data.loadTime != "-") {
                var endD = new Date(data.loadTime);
                if (nowD.getTime() > endD.getTime()) {
                    this.boxStatus.push("已装船");
                }
            } else if (data.pass_flag != "") {
                if (data.pass_flag == "已放行")
                    this.boxStatus.push("码头已放行");
                else if (data.pass_flag == "不能放行")
                    this.boxStatus.push("码头不能放行");
                else if (data.pass_flag == "取消放行")
                    this.boxStatus.push("码头取消放行");
            } else if (data.custom_flag == "Y") {
                this.boxStatus.push("海关已放行");
            } else if (data.arrivalTime && data.arrivalTime != "-") {
                var endD = new Date(data.arrivalTime);
                if (nowD.getTime() > endD.getTime()) {
                    this.boxStatus.push("已进港");
                } else {
                    this.boxStatus.push("未进港");
                }
            } else {
                this.boxStatus.push("未进港");
            }
        },

        ShipStatus(dataAarry) {
            const data = Array.isArray(dataAarry) ? dataAarry[0] : dataAarry;
            const nowD = new Date(); //当前时间
            // 有顺序关系，不要随意置换顺序
            const arr = [
                ["departureTime", "已离港", 1],
                ["closeTime", "已截关", 1],
                ["ctnApplyBeginTime", "已开港", 1],
                ["ctnApplyBeginTime", "未开港", 0],
            ];
            for (let i = 0; i < arr.length; i++) {
                const item = data[arr[i][0]];
                if (item && item !== "-") {
                    let endD = new Date(item);
                    const judgeReason = arr[i][2]
                        ? nowD.getTime() > endD.getTime()
                        : nowD.getTime() < endD.getTime();
                    if (judgeReason) {
                        this.shipStatus.push(arr[i][1]);
                        return;
                    }
                }
            }
        },

        // 船期
        shipData(data) {
            this.shipInfo = [];
            this.shipStatus = [];
            this.vesselename = "";
            this.voyage = "";
            Object.keys(data).forEach((item) => {
                if (!data[item] && item.includes("Time")) {
                    data[item] = "-";
                }
            });
            let temp = {
                ...data,
                num: 1,
                wharf: getMatou(data.wharf),
            };
            this.shipInfo.push(temp);
            this.ShipStatus(this.shipInfo);
            this.$store.commit("ship/editShipStatus", this.shipStatus);
            this.$store.commit("ship/editShipInfo", this.shipInfo);
        },
        // 预配单(只有一条)
        dockData(data) {
            this.dockInfo = [];
            const { signsTypeText, listStatText, saveTime } = data;
            var tempDock = {
                ...data,
                num: this.dockInfo.length + 1,
                listStatText: `${signsTypeText}(${listStatText})`,
                saveTime: saveTime && saveTime !== '无' ? moment(saveTime).format('YYYY-MM-DD HH:mm:ss') : "-",
            };
            tempDock && this.dockInfo.push(tempDock);

            this.$store.commit("ship/editDockInfo", this.dockInfo);
            this.searchBlno2 = "";
        },
        // 提单号，集装箱号重置(所有)
        resetAll() {
            this.searchBlno = "";
            this.containerNum = "";
            this.resetFather("table");
        },
        getString2JsonArr(data) {
            if (!data) { 
                return []
            }
            var json = eval(data);
            return json;
        },

        // 时间处理切割
        formatTime(time) {
            return time && time !== "-" ? time.slice(5, time.length - 3) : "-";
        },

        //表格序号根据分页连续显示
        tableIndex(index) {
            return (this.pagePosition - 1) * this.pageSize + index + 1;
        },
        //将数据中的字符串转为json
        getString2Json(data) {
            var json = eval("[" + data + "]")[0];
            return json;
        },
        //解决el-autocomplete 点击clearable后再输入无法显示建议列表的bug
        blurForBug() {
            document.activeElement.blur();
        },
        //设置单元格样式
        cellClass({ row, column, rowIndex, columnIndex }) {
            if (column.property) {
                //若有查验底色,若码放显示不能放行，取消放行等信息，若临近截关期或截港期，底色显示粉色
                if (
                    (column.property === "boxCusmov" && row.boxCusmov) ||
                    (column.property === "boxPassFlag" &&
                        row.boxPassFlag &&
                        row.boxPassFlag != "Y") ||
                    (column.property === "portCloseTime" &&
                        row.portCloseTime &&
                        this.judgeTimeclose(
                            row.portCloseTime,
                            this.$store.state.ship.portCloseTime,
                        )) ||
                    (column.property === "ctnApplyEndTime" &&
                        row.ctnApplyEndTime &&
                        this.judgeTimeclose(
                            row.ctnApplyEndTime,
                            this.$store.state.ship.ctnApplyEndTime,
                        ))
                ) {
                    return "pink_cell";
                }
            }
        },
        //判断时间是否小于设置的时间
        judgeTimeclose(time, markHour) {
            var date = new Date();
            var enddate = new Date(time);
            if (enddate > date) {
                return (enddate - date) / 1000 / 3600 < markHour;
            }
            return false;
        },
    },
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<!-- 处理element-ui加socped修改样式不生效bug,单独拎出来一个style -->
<style>
.blno_history {
    padding: 0 !important;
}

.el-autocomplete-suggestion {
    margin: 0;
}
</style>
<style lang="stylus" scoped>
.el-autocomplete-suggestion__wrap{
  width:300px;
  .el-autocomplete-suggestion__list{
    li {
      padding: 0 15px;
    }
    span {
      flex-grow:1;
      overflow:hidden;
      text-overflow:ellipsis;
    }
  }
  scrollbar-width: none;
  -ms-overflow-style: none;
  &::-webkit-scrollbar {
    display: none;
  }
}
/deep/ .pink_cell {
    background-color: #FF98A9 !important;
}

.radio_width {
    width: 100%;

    /deep/ .el-radio__input {
        line-height: 36px;
    }
}

.search_input_div {
    display: flex;
    flex-direction: row;
    border-radius: 4px;
    border: 1px solid #EBEEF5;
    background: #fff;
    align-items: baseline;

    // margin: auto;
    .el-input {
        width: 15rem;
    }

    /deep/ .el-input-group__append,
    .el-input-group__prepend {
        border: none;
    }
}

.refresh_btn {
    margin: 0 1rem;
    cursor: pointer;
    border-radius: 6px;
    margin-left: 30px;
    // background: #fff;
    // height: 4rem;
}

.refresh_btntwo {
    margin: 0 1rem;
    cursor: pointer;
    border-radius: 6px;
    // background: #fff;
    // height: 4rem;
}

.p_green {
    color: var(--GREEN-1E9855);
}

.p_red {
    color: var(--RED-C11C20);
}

.el-select {
    width: 12rem;
}

.status {
    width: 12rem;
    margin-left:1rem;
    color:#b88230;
}

.span-status {
    font-family: 'Lucida Calligraphy', cursive, serif, sans-serif;
    font-size: 18px;
    font-weight: bold;
    width: 15rem;
    text-align: center;
    position: relative;
    background-color: rgba(125, 220, 255, 0.2);
    // top: 25%;
}

p {
    text-align: center;
    margin: 0;
}

.txt_time {}

.el-select .el-input {
    width: 130px;
}

.input-with-select .el-input-group__prepend {
    background-color: #fff;
}

.input_div_father {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    // margin: auto;
    width: 70%;
    justify-content: center;
}

.input_div {
    display: flex;
    flex-direction: row;
    border-radius: 3px;
    border: none;
    background: #fff;
    justify-content: center;
    align-items: center;
}


.small_title {
    line-height: 1.5;
    color: #fff;
    font-size: 3rem;
    font-weight: 700;
    user-select: none;
}

.search_sailing {
    margin-top: 50px;
    width: 100%;
    display: flex;
    height: 100%;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
}

.el-select .el-input {
    width: 13rem;
}

.query-input_center:before,
.query-input_right:before {
    content: '';
    height: 2rem;
    margin-top: 12px;
    border-right: 1px solid #ccc;
}

.query-input_btn {
    cursor: pointer;
    width: 11rem;
    height: 4rem;
    font-size: 14px;
    color: #fff;
    text-align: center;
    border-radius: 0 4px 4px 0;
    background: #ff9807;
    border: none;
}

.input-with-select-top {
    background-color: #fff;
    border-radius: 4px;
}

.input-with-select-sailing {
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.data_div {
    min-height: 60vh;
    margin: 1rem;
    // margin-left: 6rem;
    // margin-right: 6rem;
    background: #fff;
    padding: 1rem 2rem;
    // border: 1px solid var(--RED-79031D);
    // border-radius: 4px;
}

.table_div {
    min-height: 60rem;
}

.passIcon {
    width: 2rem;
    height: 2rem;
    background-size: 100% 100%;
}

.data_search_div {
    // margin: 0.5rem 0;
    padding: 0.5rem 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    // background: #fff;
    .data_search_left_div {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
    }

    /deep/ .el-input-group {
        width: 25rem;
        // margin-left: 1rem;
    }

    /deep/ .el-input__inner {
        // border: 1px solid #DCDFE6;
        border: none;
    }

    /deep/ .el-input--suffix {
        padding: 0;
    }
}

/deep/ .el-table--border th.el-table__cell {
    color: #000 !important;
    text-align: center;
}

/deep/ .el-descriptions .is-bordered .el-descriptions-item__cell {
    font-weight: 700;
}

/deep/ .el-table .cell {
    font-weight: 700;
}

/deep/ .el-table .el-table__cell {
    padding: 2px 0;
    text-align: center;
}

/deep/ .el-table--striped .el-table__body tr.el-table__row--striped td.el-table__cell {
    background: #f1f3f6;
}

.el-tree {
    padding: 0 2rem 0 0;
}

.tree_btn_div {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.flex_div {
    display: flex;
    flex-direction: row;
}

.download_btn_div {
    display: flex;
    flex-direction: column;
    justify-content: center;

    .download_btn {
        margin-left: 1rem;
        margin-bottom: 1rem;
        margin-right: 1rem;
    }
}

.btn_div {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.pagination_div {
    margin: 2rem 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.table_control_div {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}

.detail {
    cursor: pointer;
    color: rgb(85, 114, 253);
    user-select: none;
}

.add_remark_p {
    cursor: pointer;
    color: rgb(85, 114, 253);
    user-select: none;
}

.line {
    width: 2px;
    height: 80%;
    background: var(--GRAY-dcdcdc);
}

.delete {
    cursor: pointer;
    color: rgb(213, 13, 0);
    user-select: none;
}

.top_div {
    background-image: linear-gradient(var(--RED-C11C20), var(--RED-79031D), var(--RED-68020F));
}

.img_back {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.btn {
    background-color: unset;
    border-radius: 4px;
    border: none;
    padding: 12px 12px;

    span {
        font-family: Microsoft YaHei UI;
        color: #fff;
        font-size: 15px;
    }
}

.span {
    font-family: Microsoft YaHei UI;
    color: #fff;
    font-size: 15px;
    margin-left: 20px;
    margin-right: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.spantwo {
    font-family: Microsoft YaHei UI;
    color: #000;
    font-size: 15px;
    margin-left: 20px;
    margin-right: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    color:rgb(64, 158, 255);
}

.inputTitle{
    font-weight:bold;
}
</style>
